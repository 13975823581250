import React, { useState } from "react";
import "./Navbar.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWhatsapp,
  faInstagram,
  faFacebook,
} from "@fortawesome/free-brands-svg-icons";

const Navbar = () => {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  return (
    <>
      <nav className="navbar">
        <div className="container flex_space">
          <div className="menu-icon" onClick={handleClick}>
            <i className={click ? "fas fa-times" : " fas fa-bars"}></i>
          </div>

          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li>
              <a
                href="https://wa.me/5569992169644"
                target="_blank"
                rel="noopener noreferrer"
                onClick={closeMobileMenu}
              >
                <FontAwesomeIcon icon={faWhatsapp} size="2x" />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/hotel_brasileiro/"
                target="_blank"
                rel="noopener noreferrer"
                onClick={closeMobileMenu}
              >
                <FontAwesomeIcon icon={faInstagram} size="2x" />
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/seu_perfil"
                target="_blank"
                rel="noopener noreferrer"
                onClick={closeMobileMenu}
              >
                <FontAwesomeIcon icon={faFacebook} size="2x" />
              </a>
            </li>
          </ul>

          <div className="login-area flex">
            <li>
              <i class="far fa-chevron-right"></i>São Fracisco do Guaporé - RO
            </li>
          </div>
        </div>
      </nav>

      <header>
        <div className="container flex_space">
          <div className="logo">
            <img src="images/hotel-logo.png" alt="" />
          </div>

          <div className="contact flex_space ">
            <div className="box flex_space">
              <div className="icons">
                <i class="fal fa-clock"></i>
              </div>
              <div className="text">
                <h4>Horário de Funcionamento</h4>
                <Link to="#">Domingo - Domingo: 24 horas</Link>
              </div>
            </div>
            <div className="box flex_space">
              <div className="icons">
                <i class="fas fa-phone-volume"></i>
              </div>
              <div className="text">
                <h4>Ligue para Nós</h4>
                <a href="tel:+5569993220694">+55 69 9322-0694</a>
              </div>
            </div>
            <div className="box flex_space">
              <div className="icons">
                <i class="far fa-envelope"></i>
              </div>
              <div className="text">
                <h4>Nosso E-mail</h4>
                <a href="mailto:info@exampal.com">info@exampal.com</a>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Navbar;
