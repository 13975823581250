import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="container">
          <div className="box">
            <div className="icon flex_space">
              <a
                href="https://www.facebook.com/seu_perfil"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-facebook-f"></i> Facebook
              </a>
              <a
                href="https://www.instagram.com/seu_perfil"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-instagram"></i> Instagram
              </a>
              <a
                href="https://wa.me/5599999999999"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-whatsapp"></i> Whatsapp
              </a>
            </div>
          </div>
        </div>
      </footer>

      <div className="legal">
        <p>© 2024 Todos os Direitos Reservados.</p>
        <p>
          Desenvolvido por <a href="brandaodev.com.br">M.Brandao Developer</a>
        </p>
      </div>
    </>
  );
};

export default Footer;
