import React from "react";
import "./About.css";

const AboutCard = () => {
  return (
    <>
      <div className="aboutCard mtop flex_space">
        <div className="row row1">
          <h4>Sobre nós</h4>
          <h1>
            Bem-vindo ao nosso hotel, onde cada detalhe é pensado para
            proporcionar a você uma <span>estadia inesquecível</span> com
            conforto e excelência.
          </h1>
          <p>
            Relaxe e aproveite uma experiência única, com atendimento
            personalizado e a serenidade que você merece.
          </p>
        </div>
        <div className="row image">
          <img src="/images/about-img-1.jpg" alt="" />
          <div className="control-btn">
            <button className="prev">
              <i className="fas fa-play"></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutCard;
