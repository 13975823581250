const GalleryData = [
  {
    img: "images/gallery-1.jpg",
    title: "Gallery One",
  },
  {
    img: "images/gallery-2.jpg",
    title: "Gallery Two",
  },
  {
    img: "images/gallery-3.jpg",
    title: "Gallery Three",
  },
  {
    img: "images/gallery-4.jpg",
    title: "Gallery Four",
  },
  {
    img: "images/gallery-5.jpg",
    title: "Gallery Five",
  },
  {
    img: "images/gallery-6.jpg",
    title: "Gallery Six",
  },
]

export default GalleryData
