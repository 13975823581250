const Data = [
  {
    image: "images/slider-1.jpg",
  },
  {
    image: "images/slider-2.jpg",
  },
  {
    image: "images/slider-3.jpg",
  },
]
export default Data
