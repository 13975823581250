import React, { useState } from "react";
import Data from "./Data";

const Home = ({ slides }) => {
  const [current, setCurrent] = useState(0);
  const length = slides.length;

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  const [formData, setFormData] = useState({
    city: "",
    checkIn: "",
    checkOut: "",
    adults: "",
    children: "",
    rooms: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { city, checkIn, checkOut, adults, children, rooms } = formData;

    const message = `Solicitação de Reserva:
    Cidade: ${city}
    Data de Início: ${checkIn}
    Data de Fim: ${checkOut}
    Adultos: ${adults}
    Crianças: ${children}
    Quartos: ${rooms}`;

    const whatsappNumber = "5569992169644";
    const whatsappURL = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(
      message
    )}`;

    window.open(whatsappURL, "_blank");
  };

  if (!Array.isArray(slides) || slides.length <= 0) {
    return null;
  }

  return (
    <>
      <section className="slider">
        <div className="control-btn">
          <button className="prev" onClick={prevSlide}>
            <i className="fas fa-caret-left"></i>
          </button>
          <button className="next" onClick={nextSlide}>
            <i className="fas fa-caret-right"></i>
          </button>
        </div>

        {Data.map((slide, index) => {
          return (
            <div
              className={index === current ? "slide active" : "slide"}
              key={index}
            >
              {index === current && <img src={slide.image} alt="Home Image" />}
            </div>
          );
        })}
      </section>

      <section className="slide-form">
        <div className="container">
          <h2>Reserve já o seu quarto</h2>
          <span>Pesquisar e reservar Quartos</span>

          <form onSubmit={handleSubmit}>
            <input
              type="text"
              name="city"
              placeholder="Sua Cidade"
              value={formData.city}
              onChange={handleChange}
            />
            <div className="flex_space">
              <input
                type="date"
                name="checkIn"
                placeholder="Data de início"
                value={formData.checkIn}
                onChange={handleChange}
              />
              <input
                type="date"
                name="checkOut"
                placeholder="Data de Saída"
                value={formData.checkOut}
                onChange={handleChange}
              />
            </div>
            <div className="flex_space">
              <input
                type="number"
                name="adults"
                placeholder="Adulto(s)(18+)"
                value={formData.adults}
                onChange={handleChange}
              />
              <input
                type="number"
                name="children"
                placeholder="Crianças(0- 17)"
                value={formData.children}
                onChange={handleChange}
              />
            </div>
            <input
              type="number"
              name="rooms"
              placeholder="Qartos"
              value={formData.rooms}
              onChange={handleChange}
            />
            <input type="submit" value="Reservar" className="submit" />
          </form>
        </div>
      </section>
    </>
  );
};

export default Home;
