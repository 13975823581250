const Wdata = [
  {
    id: 1,
    cover: "/images/works/reserve.jpg",
    title: "Faça a Reserva de um Quarto",
    desc: "",
  },
  {
    id: 1,
    cover: "/images/works/work-1.png",
    title: "Chegue Até o Hotel",
    desc: "",
  },
  {
    id: 1,
    cover: "/images/works/relaxar.png",
    title: "Sinta-se Em Casa",
    desc: "",
  },
];
export default Wdata;
