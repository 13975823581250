const PopularData = [
  {
    id: 1,
    country: "Individual com cama de casal",
    name: "Quarto individual com cama de casal",
    price: "R$ 85,00",
  },
  {
    id: 2,
    country: "Qauarto 5",
    name: "Quarto duplo com cama de casal",
    price: "R$ 135,00",
  },
  {
    id: 3,
    country: "Quarto 4",
    name: "Quarto individual com duas camas",
    price: "R$ 95,00",
  },
  {
    id: 4,
    country: "Quarto 2",
    name: "Quarto duplo com duas camas",
    price: "R$ 150,00",
  },
  {
    id: 5,
    country: "Quarto 1",
    name: "Quarto duplo com duas camas de solteiro",
    price: "R$ 140,00",
  },
  {
    id: 6,
    country: "Quarto 5",
    name: "Quarto individual com duas camas de solteiro",
    price: "R$ 85,00",
  },
  {
    id: 7,
    country: "Quarto 4",
    name: "Quarto triplo",
    price: "R$ 210,00",
  },
];

export default PopularData;
